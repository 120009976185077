import { ClipboardCopyIcon } from "@heroicons/react/outline";
import {
  EyeIcon,
  EyeOffIcon,
  InformationCircleIcon,
  KeyIcon,
} from "@heroicons/react/solid";
import { accentStyleEnum, accentStyles } from "atoms/genericStyles";
import Tooltip from "atoms/Tooltip/Tooltip";
import { formatDistance } from "date-fns";
import { useState } from "react";
import { useRecoilState } from "recoil";
import {
  notificationAtom,
  notificationStatusEnum,
} from "recoil/notification/atom";
import { toLocalTime } from "utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AccessTokenProps } from "./accessTokenTypes";

/**
 * Renders an access token with copy input
 * @category Component
 */
export function AccessToken({ token, until }: AccessTokenProps) {
  const [, setNotification] = useRecoilState(notificationAtom);
  const [tokenShow, setTokenShow] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleOnCopy = (until: string | undefined) => {
    setCopied(true);
    setTimeout(() => setCopied(false), 10000);
    setNotification({
      label: "Access token has been copied to the clipboard",
      ...(until && {
        body: `Access token is valid for ${formatDistance(
          new Date(),
          toLocalTime(new Date(until))
        )}`,
      }),
      status: notificationStatusEnum.SUCCESS,
    });
  };
  if (!token) {
    return null;
  }
  return (
    <div className="mt-2">
      <div className="text-md font-semibold flex gap-2 items-center">
        <KeyIcon
          className={`w-4 h-4 ${
            accentStyles[accentStyleEnum.SALMON].textColor
          }`}
        />
        Access token
        <Tooltip
          textComponent={
            <div>
              <p>
                Access token programmaticly authenticate your identity to the
                Deepmind.
              </p>
              <p className="mt-1 select-all font-mono bg-black text-white px-2 py-4 rounded-md">{`$ curl -X GET ${process.env.REACT_APP_BACKEND_ENDPOINT}me -H 'Authorization: Token ${token}'`}</p>
              {until && (
                <p className="mt-2 text-gray-400 text-xs">
                  Token is valid until{" "}
                  {toLocalTime(new Date(until)).toDateString()}
                </p>
              )}
            </div>
          }
        >
          <InformationCircleIcon className="h-3 w-3 text-gray-800" />
        </Tooltip>
      </div>
      <div className="relative">
        <input
          className={`bg-white text-neutral-600 rounded-lg w-full h-10 pl-4 pr-16 border-2 border-neutral-300 flex items-center justify-between `}
          type={tokenShow ? "text" : "password"}
          disabled
          value={token}
        />
        <div className="absolute top-0 right-1 h-full flex items-center gap-1 pr-1 text-neutral-800">
          <button
            className="rounded-full p-1 hover:bg-gray-300/50"
            onClick={() => setTokenShow(!tokenShow)}
            title={tokenShow ? "Hide token" : "Show token"}
          >
            {tokenShow ? (
              <EyeOffIcon className="w-4 h-4" />
            ) : (
              <EyeIcon className="w-4 h-4" />
            )}
          </button>
          <CopyToClipboard text={token} onCopy={() => handleOnCopy(until)}>
            <ClipboardCopyIcon
              className={`w-6 h-6 rounded-full p-1 hover:bg-gray-300/50 ${
                copied && "text-green-600"
              }`}
            />
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}
