import { useState, useMemo } from "react";
import {
  OfficeBuildingIcon,
  SparklesIcon,
  ThumbUpIcon,
} from "@heroicons/react/solid";
import { Avatar } from "atoms/Avatar/Avatar";
import { Button } from "atoms/Button/Button";
import { accentStyleEnum, accentStyles } from "atoms/genericStyles";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAtom } from "recoil/user/atom";
import { EditProfileModal } from "./EditProfileModal/EditProfileModal";
import { user as userApiType } from "api/apiTypes";
import useAxios from "axios-hooks";
import { mapBeUserToFeProfileInfo } from "api/apiHelpers";
import { ProfileModels } from "./ProfileModels/ProfileModels";
import { Loading } from "atoms/Loading/Loading";
import { AccessToken } from "atoms/AccessToken/AccessToken";
import { useAccount, useMsal } from "@azure/msal-react";

/**
 * Renders a profile page
 *
 * @category Component
 */
export function Profile() {
  const [profileModalOpen, setProfileModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const account = useAccount();
  const [user] = useRecoilState(userAtom);
  const { profileId } = useParams();
  const userId = profileId ?? user.cwid;
  const [
    { loading: isLoadingProfileInfo, data: fetchedUser, error: userError },
  ] = useAxios<userApiType>({
    url: `users/${userId}`,
  });

  const profileInfo = useMemo(
    () => (fetchedUser ? mapBeUserToFeProfileInfo(fetchedUser) : undefined),
    [fetchedUser]
  );

  if (!profileInfo || isLoadingProfileInfo) {
    return (
      <div className="grow flex">
        <Loading />
      </div>
    );
  }

  if (userError) {
    return <Navigate to="/404" replace />;
  }

  const handleLogout = () => {
    instance.logoutRedirect({ account, onRedirectNavigate: () => false });
    navigate("/");
  };

  return (
    <div className="container mx-auto relative grow flex flex-row bg-white">
      <div className="flex flex-col gap-4 h-auto min-w-[246px] p-4 w-3/12 border-r-2 border-slate-100 bg-gradient-to-r from-white to-slate-50 justify-start">
        <div className="mt-4 relative">
          <Avatar size="xl" ownerEmail={profileInfo.email} />
          {profileInfo.cwid.toLocaleLowerCase() ===
            user.cwid?.toLocaleLowerCase() && (
            <Button
              className="absolute left-0 -bottom-4"
              style={accentStyleEnum.SIMPLE}
              onClick={() => setProfileModalOpen(true)}
            >
              Edit profile
            </Button>
          )}
        </div>
        <div className="mt-4">
          <p className="text-xl font-bold">{profileInfo.name}</p>
          <p
            className={`${
              accentStyles[accentStyleEnum.SIMPLE].bgColor
            } mt-1 py-0.5 px-2 text-xs ${
              accentStyles[accentStyleEnum.SIMPLE].textColorContrast
            } rounded-md w-fit`}
          >
            {profileInfo.cwid}
          </p>
        </div>
        <div className="mt-2">
          <div className="text-md font-semibold flex gap-2 items-center">
            <SparklesIcon
              className={`w-4 h-4 ${
                accentStyles[accentStyleEnum.CADET].textColor
              }`}
            />
            Research interests
          </div>
          <p className={`text-neutral-600`}>
            {profileInfo.interests && profileInfo.interests.length > 0
              ? profileInfo.interests
              : "None yet"}
          </p>
        </div>
        <div className="mt-2">
          <div className="text-md font-semibold flex gap-2 items-center">
            <OfficeBuildingIcon
              className={`w-4 h-4 ${
                accentStyles[accentStyleEnum.YELLOW].textColor
              }`}
            />
            Organizations
          </div>
          <p className={`text-neutral-600 flex gap-2 flex-wrap`}>
            {profileInfo.organizations && profileInfo.organizations.length > 0
              ? profileInfo.organizations.map((org) => {
                  return (
                    <Link
                      key={`org-${org.org_id}`}
                      to={`/organizations/${org.org_name}`}
                      className={`${
                        accentStyles[accentStyleEnum.SIMPLE].bgColor
                      } mt-1 py-0.5 px-2 text-sm ${
                        accentStyles[accentStyleEnum.SIMPLE].textColorContrast
                      } rounded-md w-fit`}
                    >
                      {org.org_fullname}
                    </Link>
                  );
                })
              : "None yet"}
          </p>
        </div>
        <div className="mt-2">
          <div className="text-md font-semibold flex gap-2 items-center">
            <ThumbUpIcon
              className={`w-4 h-4 ${
                accentStyles[accentStyleEnum.GREEN].textColor
              }`}
            />
            Engagement
          </div>
          <p className={`text-neutral-600 flex gap-2 flex-wrap`}>
            {profileInfo.thumbs && profileInfo.thumbs.length > 0 ? (
              <div>{profileInfo.thumbs.length} thumbs given</div>
            ) : (
              "No thumbs given"
            )}
          </p>
          <p className={`text-neutral-600 flex gap-2 flex-wrap`}>
            {profileInfo.comments && profileInfo.comments.length > 0 ? (
              <div>{profileInfo.comments.length} comments written</div>
            ) : (
              "No comments written"
            )}
          </p>
        </div>
        {user.cwid &&
          profileInfo.cwid.toLocaleLowerCase() ===
            user.cwid.toLocaleLowerCase() && (
            <>
              <AccessToken
                token={profileInfo.apiToken}
                until={profileInfo.apiTokenExpiration}
              />
              <Button style={accentStyleEnum.SALMON} onClick={handleLogout}>
                Logout
              </Button>
            </>
          )}
      </div>
      <div className="grow p-4 flex flex-col w-9/12">
        <ProfileModels profileId={profileId} />
      </div>
      {user.cwid &&
        profileInfo.cwid.toLocaleLowerCase() ===
          user.cwid.toLocaleLowerCase() && (
          <EditProfileModal
            open={profileModalOpen}
            setOpen={setProfileModalOpen}
            {...profileInfo}
          />
        )}
    </div>
  );
}
