import { Field } from "formik";
import { IWidgetNestedProps } from "../interfaceWidgetTypes";

/**
 * Renders a text input component for a diret use in {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Atom
 */
export function InterfaceWidgetText(props: IWidgetNestedProps) {
  const { disabled, name, label, placeholder } = props;
  return (
    <div data-testid="widget-textfield-input">
      {label && <span className="text-sm text-gray-600">{label}</span>}
      <Field
        placeholder={placeholder}
        name={name}
        className="w-full h-10 pl-4 pr-12 text-slate-600 sm:text-sm border-gray-300 rounded-md rounded-lg shadow-sm"
        disabled={disabled}
      ></Field>
    </div>
  );
}
